import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { FilterInAllKeysPipe } from './filterInAllKeys.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { SortDatePipe } from './sortDate.pipe';
import { BooleanFilterPipe } from './boolean-filter.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe,FilterInAllKeysPipe,SortDatePipe,BooleanFilterPipe],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe,FilterInAllKeysPipe,SortDatePipe,BooleanFilterPipe]
})

export class PipeModule{}
