import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  jwtHelper = new JwtHelperService();


  constructor(public _firebaseAuth: AngularFireAuth, public router: Router,
    private jwtHelperService: JwtHelperService) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });

  }

  logout() {
    this._firebaseAuth.signOut();
    this.router.navigate(['/login']);
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    // return true;
    // console.log("isAuthenticated called");

        // get the token
        const token = this.getToken();
        // return a boolean reflecting
        // whether or not the token is expired
        return !this.jwtHelper.isTokenExpired(token);

  }
}
