import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { User } from './_utils/_models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));

            /////////TOKEN//////////////////////
            const token = localStorage.getItem('token');
            const user: User = JSON.parse(localStorage.getItem('user'));
            // if (token && user) {
            //     if (this.jwtHelper.isTokenExpired(token)) {
            //     this.authService.logout();
            //     } else {
            //     this.authService.currentUser = user;
            //     this.authService.decodedToken = this.jwtHelper.decodeToken(token);
            //     }
            // }
                
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}