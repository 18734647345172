// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  //apiUrl: 'http://localhost:5001/api/'

  
  // alsaafa.app
  // ok
  apiUrl: 'https://alsaafa.app/api/'

  // test.alsaafa.app
  // OK
  //apiUrl: 'https://test.alsaafa.app/api/'

  // dev.alsaafa.app
  // OK
  //apiUrl: 'https://dev.alsaafa.app/api/'

};
