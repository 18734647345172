<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item nav-search">
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/dashboard/dashboard1']"
                  *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" width="35" [src]="logoUrl"></div><span
                    class="text" style="font-size: 16px;">ALSA'AFA</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span
                  class="font-small-3">English</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="ar" (click)="ChangeLanguage('ar')"><img
                  class="langimg mr-2" src="./assets/img/flags/iq.png" alt="flag" /><span
                  class="font-small-3">عربي</span></a>

            </div>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{user?.username || user?.knownAs}}</span><span
                  class="text-right text-muted font-small-3">Available</span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/avatar-s-27.png" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="javascript:void(0)" (click)="AuthService.logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="open(content)">
                <div class="d-flex align-items-center">
                  <i class="ft-lock mr-2"></i><span>Change Password</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Popup -->
  <div class="card-content">
    <div class="card-body">
      <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Change Password</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePasswordFormSubmit()">
            <div class="form-group">
              <label for="current-password">Current Password</label>
              <div class="controls">
                <input type="password" formControlName="currentPassword" class="form-control" placeholder="Current Password"
                  [ngClass]="{ 'is-invalid': changePasswordFormSubmitted && cpf.currentPassword.invalid, 'is-valid': changePasswordFormSubmitted && !cpf.currentPassword.invalid }"
                  required>
                <div
                  *ngIf="changePasswordFormSubmitted && (cpf.currentPassword.invalid || cpf.currentPassword.errors?.required)"
                  class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="new-password">New Password</label>
              <div class="controls">
                <input type="password" formControlName="newPassword" class="form-control" placeholder="New Password"
                  [ngClass]="{ 'is-invalid': changePasswordFormSubmitted && cpf.newPassword.invalid, 'is-valid': changePasswordFormSubmitted && !cpf.newPassword.invalid }"
                  required (ngModelChange)="checkPasswords()">
                <div
                  *ngIf="changePasswordFormSubmitted && (cpf.newPassword.invalid || cpf.newPassword.errors?.required)"
                  class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required

                
                </div>

              </div>
              <div class="help-block mt-1 text-danger" *ngIf="!changePasswordForm.valid"> <i class="ft-alert-circle align-middle"></i>
                Password must be greater than or equal to 6 characters and must have at least one lowercase ('a'-'z')and must have at least one Uppercase ('A'-'Z'). and number
              </div>
            </div>
            <div class="form-group">
              <label for="retype-new-password">Retype New Password</label>
              <div class="controls">
                <input type="password" formControlName="retypeNewPassword" class="form-control"
                  placeholder="New Password" (ngModelChange)="checkPasswords()"
                  [ngClass]="{ 'is-invalid': changePasswordFormSubmitted && cpf.retypeNewPassword.invalid, 'is-valid': changePasswordFormSubmitted && !cpf.retypeNewPassword.invalid }"
                  required>
                <div
                  *ngIf="changePasswordFormSubmitted && (cpf.retypeNewPassword.invalid || cpf.retypeNewPassword.errors?.required)"
                  class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required
                </div>
              </div>
            </div>
            <div class="help-block mt-1 text-danger" *ngIf="notSamePass"> <i class="ft-alert-circle align-middle"></i>
              Not same Password
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-end">
              <button type="submit" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!changePasswordForm.valid || notSamePass">Save the new Password</button>
              <button type="reset" class="btn btn-secondary mb-1" (click)="c('Close click')">Cancel</button>
            </div>
          </form>

          
        </div>
      </ng-template>

    </div>
  </div>





</nav>