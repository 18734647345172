import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchTerm?: string, labelKey?: string, CategoryTerm?: string, categoryLabelKey?: string, CategoryValue?: string): any {
    items = items || [];
    // var objectPath = {
    //   key   :labelKey?.split('.')[0],
    //   child :labelKey?.split('.')[1]
    // }
    if(items){
      return items?.filter(
        item =>{
          return (searchTerm && searchTerm.length ? (item[labelKey || 'name']
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) === true):(item[labelKey || 'name']=== item[labelKey || 'name']=== true)
             &&
            CategoryTerm && CategoryTerm.length && CategoryValue && CategoryValue.length && categoryLabelKey && categoryLabelKey.length ? (item[CategoryTerm || 'name'][categoryLabelKey || 'name']
            .toLowerCase()
            .includes(CategoryValue.toLowerCase()) === true):(item[CategoryTerm || 'name'][categoryLabelKey || 'name']=== item[CategoryTerm || 'name'][categoryLabelKey || 'name']=== true))
          }
      );
    }

  }
}



