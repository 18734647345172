<!--Footer Starts-->
<footer class="footer hidden-print">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="alsafaLink"
      href="#">Alsaafa</a>
      <span> version: <strong>1.5</strong>  published on: 17/08//2023</span>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p>
</footer>
<!--Footer Ends-->
