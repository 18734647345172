import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, timer } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { AuthService } from '../../_utils/_services/auth.service';
import { UserService } from '../../_utils/_services/user.service';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from '../services/customizer.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  user;
  notSamePass = false;
  changePasswordFormSubmitted = false;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();

  public config: any = {};

  changePasswordForm = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required,Validators.minLength(6),Validators.maxLength(16),this.passwordValidation(/ /i)]),
    retypeNewPassword: new FormControl('', [Validators.required]),
  });

  
  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    public AuthService:AuthService,
    public UserService:UserService,
    private toastr:ToastrService,
    private modalService: NgbModal
    ) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
    this.getCurrentUserInfo();
  }

  checkPasswords() { 
    let pass = this.changePasswordForm?.get('newPassword').value;
    let retypeNewPass = this.changePasswordForm?.get('retypeNewPassword').value
    // return pass === retypeNewPass ? null : { notSamePass: true }
    if(pass != retypeNewPass)
      this.notSamePass= true;
    else
      this.notSamePass= false;

  }

  public passwordValidation(value: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let upperCaseCharacters = /[A-Z]+/g;
      let lowerCaseCharacters = /[a-z]+/g;
      let numberCharacters = /[0-9]+/g;
      // let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      const nonValid = upperCaseCharacters.test(control.value) === false || lowerCaseCharacters.test(control.value) === false || numberCharacters.test(control.value) === false;
      return nonValid ? {passwordStrength: {value: control.value}} : null;

    };
  }
  
  get cpf() {
    return this.changePasswordForm.controls;
  }

  onChangePasswordFormSubmit(){
    this.changePasswordFormSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.UserService.changePassword(this.changePasswordForm?.value).subscribe(() => {
      this.toastr.success('Password updated successfully and now you need to re-login');
    }, error => {
      // this.toastrService.error('Error', error);
      if(Array.isArray(error) == true){
        (error|| [])?.forEach(err=>{
          this.toastr.error(err?.code, err?.description);

        })
       } else if(Array.isArray(error?.errors) == true){
        (error?.errors|| [])?.forEach(err=>{
          this.toastr.error(err?.code, err?.description);

        })
       } else if(typeof error?.errors == 'object' && Array.isArray(error?.errors) == false){
        for(let key in error.errors){
          if(Array.isArray(error.errors[key]) == true){
            error.errors[key]?.forEach(err=>{
              this.toastr.error(key, err);
            })
          }
        }

       } else {
        this.toastr.error('Error', error);
       }
    }, async () => {
      this.modalService.dismissAll();
      await timer(2000).pipe(take(1)).toPromise();
      this.AuthService.logout();
    });
  }

  // Open the modal (Popup)
  open(content) {
    this.modalService.open(content).result.then((result) => {
    });
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    }
    else if (language === 'es') {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    }
    else if (language === 'pt') {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    }
    else if (language === 'de') {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
    else if (language === 'ar') {
      this.selectedLanguageText = "Arabic";
      this.selectedLanguageFlag = "./assets/img/flags/iq.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }



  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  getCurrentUserInfo() {
    // console.log('start user current')
   this.UserService.getCurrentUserInfo().subscribe((res)=>{
      this.user = res
      this.cdr.detectChanges();
    },error=>{
      this.toastr.error('Error', error);
    });

    // console.log('this.user',this.user);
  }
}
