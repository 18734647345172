

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortDate'
})
export class SortDatePipe implements PipeTransform {
  transform(items: any[], field: string, sortType?: number,): any {
    items = items || [];
    if(sortType == 1){
      return items.sort((a: any, b: any) =>
        new Date(a[field]).getTime() - new Date(b[field]).getTime()
      );
    } else {
      return items.sort((a: any, b: any) =>
        new Date(a[field]).getTime() - new Date(b[field]).getTime()
      );
    }

  }
}



