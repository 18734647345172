import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('../../_Invoices/_Invoices.module').then(m => m.InvoicesModule)
  },

  {
    path: 'dialogs',
    loadChildren: () => import('../../dialogs/dialogs.module').then(m => m.DialogsModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('../../_customers/customers.module').then(m => m.CustomersModule)
  }
];
export const Full_ROUTES_FOR_ADMIN_Role: Routes = [

  {
    path: 'users',
    loadChildren: () => import('../../_users/users.module').then(m => m.UsersModule)
  },
  // {
  //   path: 'invoices',
  //   loadChildren: () => import('../../_Invoices/_Invoices.module').then(m => m.InvoicesModule)
  // },
  {
    path: 'settings',
    loadChildren: () => import('../../_Settings/Settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('../../_Partners/Partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'budget',
    loadChildren: () => import('../../_Budget/Budget.module').then(m => m.BudgetModule)
  },
  {
    path: 'outcomeTypes',
    loadChildren: () => import('../../_outcomeTypes/outcomeTypes.module').then(m => m.OutcomeTypesModule)
  }

];
export const Full_ROUTES_FOR_ADMIN_AND_MODERATOR_Role: Routes = [

  {
    path: 'itemCategories',
    loadChildren: () => import('../../_itemCategories/itemCategories.module').then(m => m.ItemCategoriesModule)
  },
  {
    path: 'items',
    loadChildren: () => import('../../_items/items.module').then(m => m.ItemsModule)
  },
  {
    path: 'budget',
    loadChildren: () => import('../../_Budget/Budget.module').then(m => m.BudgetModule)
  },


];
