import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from '../../_utils/_services/user.service';
import { ToastrService } from 'ngx-toastr';

import { UserToCreate } from '../../_utils/_models/user/userToCreate';
// const role = {
//   users:[],
//   admin:[],
//   admin:[],
//   admin:[],
//   admin:[],
// }

@Injectable()
export class AuthGuard implements CanActivate,OnInit {
  userRole;
  get user(){return JSON.parse(localStorage.getItem('user'))}
  constructor(private authService: AuthService, private router: Router,private UserService:UserService,private toastr:ToastrService) { }
  getCurrentUserInfo() {


  }

  async getUserRole() {
    this.UserService.getCurrentUserInfoWithAwait().then((res) => {
        this.userRole =  res?.userRoles?.map(role => role?.name)?.join(',');
      }, error => {
        // this.toastr.error('Error', error);
    });
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuth = this.authService.isAuthenticated();
    var roles = state['_root']?.children[0]?.value?.data?.role;

    if (!isAuth) {
      this.router.navigate(['/pages/login']);
      return false;
    }
    else {

      if(this.userRole?.userRoles[0].name.toLowerCase()?.includes('member'))
        this.router.navigate(['/customers/customers-list']);

      if(roles){
        // console.log('route',route)
        // console.log('state',state)
        var userRolesMap = this.user['userRoles']?.map(role=>{return role?.name});
        var roleFound = false;
        userRolesMap.forEach(role =>{
          if(roles?.indexOf(role?.toLowerCase()) != -1){

            return roleFound =  true;

          }

        })
        if(
          !userRolesMap?.includes('admin')
          &&
         ( state?.url?.toLocaleLowerCase()?.includes("/itemcategories/itemcategories-edit")
          ||
          state?.url?.toLocaleLowerCase()?.includes("/itemcategories/itemcategories-creation")
          ||
          state?.url?.toLocaleLowerCase()?.includes("/itemcategories/item-edit")
          ||
          state?.url?.toLocaleLowerCase()?.includes("/itemcategories/item-creation"))
        ){
          this.router.navigate(['/']);
          return false
        } if(
          userRolesMap?.includes('member')
          &&
         (
           state?.url?.toLocaleLowerCase()?.includes("/customers/customers-edit")
          ||
          state?.url?.toLocaleLowerCase()?.includes("/customers/customers-creation")
          ||
          state?.url?.toLocaleLowerCase()?.includes("/invoices/invoices-creation")
          ||
          state?.url?.toLocaleLowerCase()?.includes("/dashboard/dashboard1")
         )
        ){
          this.router.navigate(['/customers/customers-list']);
          return false
        } else {
          return roleFound

        }

      } else {
        return true;
      }

    }
  }

  async ngOnInit(){
    this.userRole = await this.UserService.getCurrentUserInfoWithAwait();
  }
}
