import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanGilter'
})
export class  BooleanFilterPipe implements PipeTransform {
  transform(items: any[], value?:boolean, key?:string): any {
    items = items || [];
    // var objectPath = {
    //   key   :labelKey?.split('.')[0],
    //   child :labelKey?.split('.')[1]
    // }
    if(items){
        console.log('boolean',value,key)
      return items?.filter(
          
        item =>{
            console.log('boolean',item[key || 'status'])
            if(value){
                return item[key || 'status']?.toString().includes(value)  
            } else {
                return item
            }
         
           
            }
      );
    }

  }
}



