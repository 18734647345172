


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterInAllKeys'
})
export class FilterInAllKeysPipe implements PipeTransform {
  transform(items: any[], searchTerm?: string, labelObjects?: string): any {
    items = items || [];

    function trimString(s) {
      var l=0, r=s.length -1;
      while(l < s.length && s[l] == ' ') l++;
      while(r > l && s[r] == ' ') r-=1;
      return s.substring(l, r+1);
    }

    function compareObjects(o1, o2) {
      var k = '';
      for(k in o1) if(o1[k] != o2[k]) return false;
      for(k in o2) if(o1[k] != o2[k]) return false;
      return true;
    }

    function itemExists(haystack, needle) {
      for(var i=0; i<haystack.length; i++) if(compareObjects(haystack[i], needle)) return true;
      return false;
    }


    function searchFor(toSearch) {
      var results = [];
      toSearch = trimString(toSearch); // trim it
      for(var i=0; i<items.length; i++) {
        for(var key in items[i]) {
          var label = labelObjects?.split(',').find(l=>{return l.includes('key')})?.split('.')[1]
          items[i][key] = typeof items[i][key] == 'number' ? items[i][key].toString() : items[i][key];
          // items[i][key] = typeof items[i][key] == 'object' ? items[i][key][label] : items[i][key][label];
          var itemnObject = items[i][key] == 'object' ? items[i][key][label] :  items[i][key]

          if(items[i][key] != null && items[i][key] != undefined  && typeof items[i][key] != 'object' && typeof items[i][key] != 'boolean' &&  items[i][key]?.toLowerCase()?.indexOf(toSearch)!=-1) {

              if(!itemExists(results, items[i])) results.push(items[i]);


          } else if(typeof items[i][key] == 'object'){
            var objectKey = labelObjects?.split(',')?.find(l=> l.includes(key) == true)?.split('.')[1];
            if(objectKey && items[i][key][objectKey]?.toLowerCase()?.indexOf(toSearch)!=-1){
              if(!itemExists(results, items[i])) results.push(items[i]);
            }
          }
        }
      }
      return results;
    }
    return searchFor(searchTerm?.toLowerCase())
  }
}



